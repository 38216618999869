var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    { staticClass: "bg-light" },
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "start" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-stepper",
                    {
                      attrs: { flat: "", "non-linear": "" },
                      model: {
                        value: _vm.step,
                        callback: function ($$v) {
                          _vm.step = $$v
                        },
                        expression: "step",
                      },
                    },
                    [
                      _c(
                        "v-stepper-items",
                        [
                          _c(
                            "v-stepper-content",
                            { attrs: { step: 1 } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "mb-2",
                                  attrs: { column: "", "align-center": "" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      class: {
                                        "pa-10":
                                          _vm.$vuetify.breakpoint.mdAndUp,
                                      },
                                      attrs: {
                                        elevation: "3",
                                        color: "white",
                                        rounded: "",
                                        loading: _vm.feedbackRequest.isBusy,
                                        width: _vm.$vuetify.breakpoint.lg
                                          ? "75%"
                                          : _vm.$vuetify.breakpoint.xl
                                          ? "50%"
                                          : "100%",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [
                                              _vm._v(
                                                "fa-light fa-message-lines"
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("feedbackPage.header")
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c("v-card-subtitle", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "feedbackPage.questionCommon"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "text-center" },
                                        [
                                          _c("v-rating", {
                                            attrs: {
                                              color: "amber darken-2",
                                              "background-color":
                                                "grey lighten-2",
                                              hover: "",
                                              large: "",
                                            },
                                            model: {
                                              value: _vm.rating,
                                              callback: function ($$v) {
                                                _vm.rating = $$v
                                              },
                                              expression: "rating",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              label: _vm.$t(
                                                "feedbackPage.comment"
                                              ),
                                              "auto-grow": "",
                                              outlined: "",
                                              width: "100%",
                                              "hide-details": "",
                                            },
                                            model: {
                                              value: _vm.comment,
                                              callback: function ($$v) {
                                                _vm.comment = $$v
                                              },
                                              expression: "comment",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-actions",
                                        { staticClass: "justify-center" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                                disabled: _vm.rating === null,
                                              },
                                              on: { click: _vm.submit },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "feedbackPage.buttonSubmit"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: 2 } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "mb-2",
                                  attrs: { column: "", "align-center": "" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "d-flex flex-column justify-center pa-10",
                                      attrs: {
                                        elevation: "3",
                                        color: "white",
                                        rounded: "",
                                        loading: _vm.feedbackRequest.isBusy,
                                        width: _vm.$vuetify.breakpoint.lg
                                          ? "75%"
                                          : _vm.$vuetify.breakpoint.xl
                                          ? "50%"
                                          : "100%",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            "x-large": "",
                                            color: "primary",
                                          },
                                        },
                                        [_vm._v("fa-light fa-message-smile")]
                                      ),
                                      _c(
                                        "h2",
                                        { staticClass: "text-center py-4" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "feedbackPage.succeededTitle"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("p", { staticClass: "text-center" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "feedbackPage.succeededText"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }